export const LOADING = 'LOADING';

export const ALL_RECORDS = 'ALL_RECORDS';
export const ADD_RECORD = 'ADD_RECORD';
export const EDIT_RECORD = 'EDIT_RECORD';
export const DELETE_RECORD = 'DELETE_RECORD';

// type for sidebar layout component
export const SIDEBAR_COLLAPSED = 'SIDEBAR_COLLAPSED';

// types for checkin
// export const CHECKIN_TYPE = "CHECKIN_TYPE";
export const SET_CHECKIN_DATA = 'SET_CHECKIN_TYPE';
// export const REMOVE_CHECKIN_TYPE = "REMOVE_CHECKIN_TYPE";

// type forsaving property id from top dropdown
export const SAVE_PROPERTY_ID = 'SAVE_PROPERTY_ID';

// saving house rules
export const SAVING_HOUSE_RULES = 'SAVING_HOUSE_RULES';

// saving doc no 1
export const DOC_NO_1 = 'DOC_NO_1';

// saving room no.  for checkout and empty
export const SAVING_CHECKOUT_ROOM_NO = 'SAVING_CHECKOUT_ROOM_NO';

// saving permissions for check
export const SAVE_PERMISSION = 'SAVE_PERMISSION';

// logout
export const LOGOUT = 'LOGOUT';
export const LOGOUT1 = 'LOGOUT1';
