export const API_URL_LIST = {
    /* 
    **********************
    ? propert module API's
    **********************/
    ADD_PROPERTY_API_URL: '/api/property/add',
    GET_ALL_PROPERTIES_API_URL: '/api/property/listing?url=1',
    GET_SINGLE_PROPERTIES_API_URL: '/api/property/propertybyid/',
    GET_ALL_PROPERTY_UNITS_API_URL: '/api/propertyunits/listing',
    DELETE_PROPERTY_API_URL: '/api/property/delete',
    API_PROPERTY_LIST_DROPDOWN: '/api/property/dropdown',
    /* 
    **********************
    ? CUSTOMER MODULE APIS
    **********************/
    GET_ALL_CUSTOMERS_API_URL: '/api/customer/listing?url=1',
    UPDATE_CUSTOMER_API_URL: '/api/customer/updateCustomer',
    ADD_CUSTOMER_API_URL: '/api/customer/add',
    GET_SINGLE_CUSTOMER_API_URL: '/api/customer/getCustomerByID/',
    DELETE_CUSTOMERS_API_URL: '/api/customer/delete',
    GET_CUSTOMER_DROPDOWNLIST_API_URL: '/api/customer/dropdown',
    /* 
    **********************
    ? EMPLOYEE MODULES
    **********************/
    GET_ALL_EMPLOYEES_API_URL: '/api/employee/listing?url=1',
    GET_SINGLE_EMPLOYEE_API_URL: '/api/employee/getEmployee',
    UPDATE_EMPLOYEES_API_URL: '/api/employee/update',
    DELETE_EMPLOYEES_API_URL: '/api/employee/delete',
    ADD_EMPLOYEES_API_URL: '/api/employee/add',
    GET_EMPLOYEE_GROUPS_API_URL: '/api/employee/getemployeegroups',
    /* 
    **********************
    ? get all regions api url
    **********************/
    GET_REGIONS_API_URL: '/api/customer/regions',
    /* 
    **********************
    ? checkin module apis
    **********************/
    ADD_CHECK_IN_API_URL: '/api/checkin/add',
    ADD_CHECK_IN_VIDEOS_API_URL: '/api/checkin/add-videos',
    GET_CHECKIN_BY_PROPERTY_API_URL: '/api/occupancy/getCheckinByProperty?test=1',
    GET_RECENT_CHECKINS_LIST: '/api/checkin/recent-checkins?test=1',
    UPDATE_RECENT_CHECKIN_API_URL: '/api/checkin/update',
    /* 
    **********************
    ? checkout apis url
    **********************/
    ADD_CHECK_OUT_API_URL: '/api/checkout/add',
    GET_RECENT_CHECKOUT_API_URL: '/api/checkout/recent-checkouts?url=1',
    UPDATE_RECENT_CHECKOUT_API_URL: '/api/checkout/update',
    /* 
    **********************
    ? incident apis url
    **********************/
    ADD_INCIDENT_API_URL: '/api/incident/add',
    UPDATE_INCIDENT_API_URL: '/api/incident/updateIncident',
    GET_ALL_RECENT_INCIDENTS_API_URL: '/api/incident/recent-incidents?url=1',
    GET_CURRENT_WEEK_INCIDENTS_API_URL: '/api/incident/getCurrentWeekIncidents?url=1',
    /* 
    **********************
    ? maintenance apis url
    **********************/
    ADD_MAINTENANCE_API_URL: '/api/maintenance/add',
    GET_ALL_RECENT_MAINTENANCE_REQUESTS_API_URL: '/api/maintenance/recentMaintenanceRequests?url=1',
    GET_SINGLE_MAINTENANCE_API_URL: '/api/maintenance/getMaintenanceById/',
    UPDATE_SINGLE_MAINTENANCE_API_URL: '/api/maintenance/updateMaintenanceRequest',
    /* 
    **********************
    ? property units
    **********************/
    GET_PROPERTY_UNITS_BY_ID_API_URL: '/api/propertyunits/getPropertyUnitbyPID',
    ADD_PROPERTY_UNIT_BY_PROPERTY_ID_API_URL: '/api/propertyunits/add',
    DELETE_PROPERTY_UNIT_BY_PROPERTY_ID_API_URL: '/api/propertyunits/delete',

    UPDATE_PROPERTY_BY_PROPERTY_ID_API_URL: 'api/property/update',

    UPDATE_PROPERTY_BY_PROPERTY_UNIT_ID_API_URL: '/api/propertyunits/update',
    /* 
    **********************
    ? GET ALL CECKED-IN ROOMS, FOR REPORT MODULE,
    **********************/
    GET_ALL_OCCUPANCY_REPORT_API_URL: '/api/occupancy/reports?url=1',
    UPDATE_OCCUPANCY_REPORT_API_URL: '/api/occupancy/update',
    /* 
    ****************************************************************
    ? get a room when a user is going to perform checkout (select room from dropdown),
    ************************************************************************/

    /* 
    **********************
    ? get all rooms, for dropdown menu,
    **********************/
    GET_ALL_ROOMS_DROPDOWN_MENU_API_URL: '/api/propertyunits/dropdown',

    //get Manager Data
    GET_ALL_MANAGER_API_URL: '/api/user/get/',

    // get All Residents
    GET_ALL_RESIDENTS_API_URL: '/api/checkin/residents',

    //  get Incident Severity
    GET_INCIDENT_SERVERITY_RESIDENTS_API_URL: '/api/dropdown/incidentSeverity',

    //  get Incident Status
    GET_INCIDENT_STATUS_API_URL: '/api/dropdown/incidentStatus',

    // getting incidents
    GET_SINGLE_INCIDENT_BY_ID: '/api/incident/getIncidentByID/',

    //  get Urgency DropDown API
    GET_URGENCY_DROPDOWN_API_URL: '/api/dropdown/maintenanceUrgency',

    //  get Repair &  Purchase  DropDown API
    GET_REPAIR_AND_PURCHASE_DROPDOWN_API_URL: '/api/dropdown/repairOrPurchase',

    //get Reason For request
    GET_REASON_FOR_REQUEST_API_URL: '/api/dropdown/reasonForRequest',

    //get Technical issue List
    GET_TECHNICAL_ISSUE_API_URL: '/api/dropdown/technicalIssue',

    //get Reason For Leaving DropDown
    GET_REASON_FOR_LEAVING_API_URL: '/api/dropdown/reasonForLeaving',

    //get Type of Meter DropDown
    GET_TYPE_METER_DROPDOWN_API_URL: '/api/dropdown/typeOfMeter',
    GET_ALL_PURCHASE_ORDER_NUMBER_API_URL: '/api/occupancy/getPONumber',
    /* 
    **********************
    ? status apis,
    **********************/
    REGISTER_A_NEW_STATUS_API_URL: '/api/residents/register-status',
    GET_FULL_STATUS_WEEKLY_REPORT_API_URL: '/api/residents/full-weekly-report?url=1',
    GET_ALL_SUBMITTED_STATUS_REPORTS_API_URL: '/api/residents/status-report?url=1',
    UPDATE_STATUS_REPORTS_API_URL: '/api/residents/update-status-report',
    /* 
    **********************
    ? meter reading,
    **********************/
    ADD_METER_READING_API_URL: '/api/maintenance/add-meter-reading',
    GET_ALL_METER_READINGS_API_URL: '/api/maintenance/getMeterReadings?url=1',

    /* 
    **********************
    ? sending file email api,
    **********************/
    SEND_REPORT_TO_EMAIL_API_URL: '/api/occupancy/send-email',
    /* 
    **********************
    ? sms api,
    **********************/
    SEND_SMS_TO_ALL_CURRENT_RESIDENTS_API_URL: '/api/occupancy/send-sms',

    /* 
    **********************
    ? warning letter module api url list,
    **********************/
    ADD_WARNING_LETTER_API_URL: '/api/warning/add',
    GET_LAST_7_DAYS_WARNING_LETTERS_API_URL: '/api/warning/getWarningLetter?url=1',
    ADD_WARNING_TEMPLATE_API_URL: '/api/warning/addTemplate',
    GET_ALL_WARNING_TEMPLATES_API_URL: '/api/warning/getTemplates?url=1',
    DELETE_WARNING_TEMPLATES_API_URL: '/api/warning/deleteTemplate',
    /* 
    **********************
    ? modules api for access control,
    **********************/
    GET_ALL_MODULE_CONTROL_API_URL: '/api/user/getAllModules?url=1',
    GET_ALL_SUB_MODULE_CONTROL_API_URL: '/api/user/getSubModules?url=1',
    GIVE_ACCESS_MODULE_API_URL: '/api/user/accessControl',
    DELETE_PERMISSION_MODULE_API_URL: '/api/user/deletePermission',
    /* 
    **********************
    ?  tags apis url,
    **********************/
    ADD_TAG_API_URL: '/api/occupancy/addTag',
    REMOVE_TAG_API_URL: '/api/occupancy/removeTag',
    /* 
    **********************
    ?  get user profile,
    **********************/
    GET_USER_PROFILE_API_URL: '/api/user/getProfile',
    UPDATE_USER_PROFILE_API_URL: '/api/user/updateProfile',
    /* 
    **********************
    ?  screen/pages api's url,
    **********************/
    GET_ALL_SCREEN_API_URL: '/api/dropdown/getScreens',
    /* 
    **********************
    ?  tutorials api's url,
    **********************/
    ADD_TUTORIAL_VIDEO_API_URL: '/api/setting/addTutorial',
    DELETE_TUTORIAL_VIDEO_API_URL: '/api/setting/deleteTutorial',
    GET_ALL_TUTORIALS_API_URL: '/api/setting/getTutorials?url=1',
    /* 
    **********************
    ?  training api's url,
    **********************/
    ADD_TRAINING_API_URL: '/api/training/add',
    GET_ALL_TRAINING_API_URL: '/api/training/getTrainings?url=1',
    DELETE_TRAINING_API_URL: '/api/training/delete',
    /* 
    **********************
    ?  current residents api's url,
    **********************/
    GET_ALL_CURRENT_RESIDENTS: '/api/occupancy/currentResidents?url=1',
    GET_ALL_OLD_RESIDENTS: '/api/occupancy/oldResidents?url=1',
    GET_SINGLE_RESIDENTS_API_URL: '/api/occupancy/residentDetails',
    /* 
    **********************
    ?  tasks apis,
    **********************/
    GET_ALL_TASKS_CATEGORIES_URL: '/api/dropdown/taskCategories?url=1',
    TASK_STATUS_API_URL: '/api/dropdown/taskStatus?url=1',
    ADD_TASK_API_URL: '/api/task/add',
    UPDATE_TASK_API_URL: '/api/task/update',
    UPDATE_STATUS_TASK_API_URL: '/api/task/updateStatus',
    GET_ALL_PROPERTY_TASKS_API_URL: '/api/task/propertyTasks?url=1',
    GET_ALL_USER_TASKS_API_URL: '/api/task/userTasks?url=1',
    DELETE_TASK_API_URL: '/api/task/delete',
    GET_SINGLE_TASK_API_URL: '/api/task/getTaskById/',
    ARCHIEVE_TASK_API_URL: '/api/task/archive',
    GET_ARCHIEVE_TASK_API_URL: '/api/task/archivedTasks?url=1',
    //
    /**************************************** */
    UPDATE_RESIDENT_API_URL: '/api/occupancy/updateResident',
    //
    SMS_SERVER_API_KEY_API_URL: '/api/setting/updateSmsSetting',
    GET_SMS_SERVER_API_KEY_API_URL: '/api/setting/getSmsSetting',
    //
    CONVER_XLS_TO_PDF: 'https://v2.convertapi.com/convert/xlsx/to/pdf?Secret=6mThx2RjxpCFr19x&StoreFile=true',

    //
    ADD_INVOICE_DATA: '/api/occupancy/addInvoiceData',

    GET_RESIDENT_UNIQUE_ID: '/api/resident/id-generated',
    POST_DATABASE_SYNC: '/api/setting/sync-csv-to-gdrive'
};
