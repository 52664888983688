import { notification } from 'antd';

export const openNotification = (placement, heading, description) => {
    notification.destroy();
    notification.info({
        message: (heading && heading) || '',
        description: (description && description) || '',
        placement: (placement && placement) || 'top',
        style: {
            backgroundColor: '#c91d67',
            fontFamily: 'Lato',
            color: '#61273e',
            borderRadius: '15px'
        },
        maxCount: 2
    });
};
