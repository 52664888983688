import React from 'react';
const Home = React.lazy(() => import('../../Pages/Home/Index'));
const Reports = React.lazy(() => import('../../Pages/Reports'));
const Properties = React.lazy(() => import('../../Pages/Properties'));
const Employees = React.lazy(() => import('../../Pages/Employees'));
const Customers = React.lazy(() => import('../../Pages/Customers'));
// const Support = React.lazy(() => import('../../Pages/Support'));
const Reporting = React.lazy(() => import('../../Pages/Reporting'));
const WeeklyReports = React.lazy(() => import('../../Pages/Reporting/WeeklyReports'));
const FullResidentReport = React.lazy(() => import('../../Pages/Reporting/FullResidentReport'));
const Training = React.lazy(() => import('../../Pages/Training'));
const MyProfile = React.lazy(() => import('../../Pages/MyProfile'));
///
//
//
//
//
//
//
//

const Settings = React.lazy(() => import('../../Pages/Settings'));
const PropertiesPhotos = React.lazy(() => import('../../Pages/Settings/properties-photos/PropertiesPhotos'));
const MaintenancePhotos = React.lazy(() => import('../../Pages/Settings/maintenance-photos/MaintenancePhotos'));
const MeterReadingPhotos = React.lazy(() => import('../../Pages/Settings/meter-reading-photos/MeterReadingPhotos'));
const RoomPhotos = React.lazy(() => import('../../Pages/Settings/room-photos/RoomPhotos'));
const WarningLetterTemplates = React.lazy(() => import('../../Pages/Settings/warning-letter-templates/WarningLetterTemplates'));
const EmployeePhotos = React.lazy(() => import('../../Pages/Settings/employee-photos/EmployeePhotos'));
const ResidentPhotos = React.lazy(() => import('../../Pages/Settings/resident-photos/ResidentPhotos'));
const TaskPhotos = React.lazy(() => import('../../Pages/Settings/task-photos/TaskPhotos'));
const TutorialsList = React.lazy(() => import('../../Pages/Settings/tutorials-list/TutorialsList'));
const Trainings = React.lazy(() => import('../../Pages/Settings/trainings/Trainings'));
const SMSServer = React.lazy(() => import('../../Pages/Settings/sms-server'));
//
//
//
//
//
//

//
//
//
const Task = React.lazy(() => import('../../Pages/Task'));
const UpdateTask = React.lazy(() => import('../../Pages/Task/UpdateTask'));
const AddTask = React.lazy(() => import('../../Pages/Task/AddTask'));
const ArchiveTasks = React.lazy(() => import('../../Pages/Task/ArchieveTasks'));

const Archive = React.lazy(() => import('../../Pages/Archive'));

const Residents = React.lazy(() => import('../../Pages/Residents'));
const Checkin = React.lazy(() => import('../../Pages/Checkin'));
const RecentCheckins = React.lazy(() => import('../../Pages/Checkin/RecentCheckins'));
const EditRecentCheckins = React.lazy(() => import('../../Pages/Checkin/EditRecentPage'));
const ListCurrentResidents = React.lazy(() => import('../../Pages/ListCurrentResidents/index'));

const RecentCheckouts = React.lazy(() => import('../../Pages/Checkout/RecentCheckouts'));

const RecentMaintenanceRequests = React.lazy(() => import('../../Pages/RecentMaintenanceRequests/Index'));
const RecentMaintenance = React.lazy(() => import('../../Pages/RecentMaintenance/index'));

const GroupMessages = React.lazy(() => import('../../Pages/GroupMessages'));
const Checkout = React.lazy(() => import('../../Pages/Checkout'));
const Incidents = React.lazy(() => import('../../Pages/Incidents'));
const UpdateIncident = React.lazy(() => import('../../Pages/Incidents/UpdateIncident'));
const AccessControl = React.lazy(() => import('../../Pages/AccessControl'));
const Warnings = React.lazy(() => import('../../Pages/Warnings'));
const Status = React.lazy(() => import('../../Pages/Status'));
const MeterReadings = React.lazy(() => import('../../Pages/MeterReadings'));
const PropertyMaintenance = React.lazy(() => import('../../Pages/PropertyMaintenence'));
const EditPropertyMaintenance = React.lazy(() => import('../../Pages/PropertyMaintenence/EditMaintenance'));
const PropertyUnites = React.lazy(() => import('../../Pages/Properties/PropertyUnits'));

const RecentIncident = React.lazy(() => import('../../Pages/RecentIncidents'));

//children routes view
const EmployeeDetails = React.lazy(() => import('../../Pages/Employees/EmployeeDetails'));
const CustomerDetails = React.lazy(() => import('../../Pages/Customers/CustomerDetails'));
const PropertyDetails = React.lazy(() => import('../../Pages/Properties/PropertyDetails'));
const RegisterStatus = React.lazy(() => import('../../Pages/Status/StatusForm'));

const EditResident = React.lazy(() => import('../../Pages/Residents/EditResident'));

const routes = [
    {
        path: '/home',
        exact: true,
        name: 'Home',
        Component: Home,
        type: 'private',
        breadcrumb: [{ name: 'Home', link: '/home' }]
    },
    {
        path: '/reports',
        exact: true,
        name: 'Reports',
        Component: Reports,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/reports' },
            { name: '/Occupancy/Reports', link: '/reports' }
        ]
    },
    {
        path: '/properties',
        exact: true,
        name: 'Properties',
        Component: Properties,
        type: 'private',
        breadcrumb: [{ name: 'Properties', link: '/properties' }]
    },
    {
        path: '/employees',
        exact: true,
        name: 'Employees',
        Component: Employees,
        type: 'private',
        breadcrumb: [{ name: 'Employees', link: '/employees' }]
    },
    {
        path: '/customers',
        exact: true,
        name: 'Customers',
        Component: Customers,
        type: 'private',
        breadcrumb: [{ name: 'Customers', link: '/customers' }]
    },
    {
        path: '/reporting',
        exact: true,
        name: 'Reporting',
        Component: Reporting,
        type: 'private',
        breadcrumb: [{ name: 'Reporting', link: '/reporting' }]
    },
    {
        path: '/reporting/weekly-reports',
        exact: true,
        name: 'Weekly Reports',
        Component: WeeklyReports,
        type: 'private',
        breadcrumb: [
            { name: 'Reporting', link: '/reporting' },
            { name: '/Weekly-Reports', link: '/reporting/weekly-reports' }
        ]
    },
    {
        path: '/reporting/full-residents-reports',
        exact: true,
        name: 'Full Residents Reports',
        Component: FullResidentReport,
        type: 'private',
        breadcrumb: [
            { name: 'Reporting', link: '/reporting' },
            { name: '/Full-Residents-Reports', link: '/reporting/full-residents-reports' }
        ]
    },
    {
        path: '/training',
        exact: true,
        name: 'Training',
        Component: Training,
        type: 'private',
        breadcrumb: [{ name: 'Training', link: '/training' }]
    },
    {
        path: '/my-profile',
        exact: true,
        name: 'MyProfile',
        Component: MyProfile,
        type: 'private',
        breadcrumb: [{ name: 'My Profile', link: '/my-profile' }]
    },
    {
        path: '/settings',
        exact: true,
        name: 'Settings',
        Component: Settings,
        type: 'private',
        breadcrumb: [{ name: 'Settings', link: '/settings' }]
    },
    {
        path: '/settings/properties-photos',
        exact: true,
        name: 'Properties Photos',
        Component: PropertiesPhotos,
        type: 'private',
        breadcrumb: [
            { name: 'Settings', link: '/settings' },
            { name: '/properties-photos', link: '/settings/properties-photos' }
        ]
    },
    {
        path: '/settings/maintenance-photos',
        exact: true,
        name: 'Maintenance Photos',
        Component: MaintenancePhotos,
        type: 'private',
        breadcrumb: [
            { name: 'Settings', link: '/settings' },
            { name: '/maintenance-photos', link: '/settings/maintenance-photos' }
        ]
    },
    {
        path: '/settings/meter-reading-photos',
        exact: true,
        name: 'Meter Reading Photos',
        Component: MeterReadingPhotos,
        type: 'private',
        breadcrumb: [
            { name: 'Settings', link: '/settings' },
            { name: '/meter-reading-photos', link: '/settings/meter-reading-photos' }
        ]
    },
    {
        path: '/settings/room-photos',
        exact: true,
        name: 'Room Photos',
        Component: RoomPhotos,
        type: 'private',
        breadcrumb: [
            { name: 'Settings', link: '/settings' },
            { name: '/room-photos', link: '/settings/room-photos' }
        ]
    },
    {
        path: '/settings/warning-letter-templates',
        exact: true,
        name: 'Room Photos',
        Component: WarningLetterTemplates,
        type: 'private',
        breadcrumb: [
            { name: 'Settings', link: '/settings' },
            { name: '/warning-letter-templates', link: '/settings/warning-letter-templates' }
        ]
    },
    {
        path: '/settings/employee-photos',
        exact: true,
        name: 'Employee Photos',
        Component: EmployeePhotos,
        type: 'private',
        breadcrumb: [
            { name: 'Settings', link: '/settings' },
            { name: '/employee-photos', link: '/settings/employee-photos' }
        ]
    },
    {
        path: '/settings/resident-photos',
        exact: true,
        name: 'ResidentPhotos',
        Component: ResidentPhotos,
        type: 'private',
        breadcrumb: [
            { name: 'Settings', link: '/settings' },
            { name: '/resident-photos', link: '/settings/resident-photos' }
        ]
    },
    {
        path: '/settings/task-photos',
        exact: true,
        name: 'TaskPhotos',
        Component: TaskPhotos,
        type: 'private',
        breadcrumb: [
            { name: 'Settings', link: '/settings' },
            { name: '/task-photos', link: '/settings/task-photos' }
        ]
    },
    {
        path: '/settings/tutorials-list',
        exact: true,
        name: 'Tutorials List',
        Component: TutorialsList,
        type: 'private',
        breadcrumb: [
            { name: 'Settings', link: '/settings' },
            { name: '/tutorials-list', link: '/settings/tutorials-list' }
        ]
    },
    {
        path: '/settings/trainings',
        exact: true,
        name: 'Trainings',
        Component: Trainings,
        type: 'private',
        breadcrumb: [
            { name: 'Settings', link: '/settings' },
            { name: '/trainings', link: '/settings/trainings' }
        ]
    },
    {
        path: '/settings/sms-server',
        exact: true,
        name: 'SMSServer',
        Component: SMSServer,
        type: 'private',
        breadcrumb: [
            { name: 'Settings', link: '/settings' },
            { name: '/sms-server', link: '/settings/sms-server' }
        ]
    },
    {
        path: '/task',
        exact: true,
        name: 'Task',
        Component: Task,
        type: 'private',
        breadcrumb: [{ name: 'Task', link: '/task' }]
    },
    {
        path: '/task/update-task/:id',
        exact: true,
        name: 'Update Task',
        Component: UpdateTask,
        type: 'private',
        breadcrumb: [
            { name: 'Task', link: '/task' },
            { name: '/update-task', link: '/task/update-task' }
        ]
    },
    {
        path: '/task/add-task',
        exact: true,
        name: 'Add Task',
        Component: AddTask,
        type: 'private',
        breadcrumb: [
            { name: 'Task', link: '/task' },
            { name: '/add-task', link: '/task/add-task' }
        ]
    },
    {
        path: '/task/archive-tasks',
        exact: true,
        name: 'Archive Tasks',
        Component: ArchiveTasks,
        type: 'private',
        breadcrumb: [
            { name: 'Task', link: '/task' },
            { name: '/archive-task', link: '/task/archive-tasks' }
        ]
    },
    {
        path: '/archive',
        exact: true,
        name: 'Archive',
        Component: Archive,
        type: 'private',
        breadcrumb: [{ name: 'Archive', link: '/archive' }]
    },

    {
        path: '/residents',
        exact: true,
        name: 'Residents',
        Component: Residents,
        type: 'private',
        breadcrumb: [{ name: 'Residents', link: '/residents' }]
    },
    {
        path: '/residents/edit/:id',
        exact: true,
        name: 'Edit Resident',
        Component: EditResident,
        type: 'private',
        breadcrumb: [{ name: 'Residents', link: '/residents/edit' }]
    },
    {
        path: '/support',
        exact: true,
        name: 'Support',
        Component: TutorialsList,
        type: 'private',
        breadcrumb: [{ name: 'Support', link: '/support' }]
    },
    {
        path: '/home/checkin',
        exact: true,
        name: 'Checkin',
        Component: Checkin,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            { name: '/Occupancy/Checkin', link: '/home/checkin' }
        ]
    },
    {
        path: '/home/checkin/recent-checkins',
        exact: true,
        name: 'RecentCheckins',
        Component: RecentCheckins,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            // { name: "/Occupancy/checkin", link: "/home/checkin" },
            {
                name: '/Occupancy/Recent Checkins',
                link: '/home/checkin/'
            }
        ]
    },
    {
        path: '/home/checkin/recent-checkins/edit/:id',
        exact: true,
        name: 'Edit Recent Checkins',
        Component: EditRecentCheckins,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            // { name: "/Occupancy/checkin", link: "/home/checkin" },
            {
                name: '/Occupancy/Edit Recent Checkins',
                link: '/home/checkin/'
            }
        ]
    },
    // RecentIncident

    {
        path: '/home/recent-maintenance-requests',
        exact: true,
        name: 'Recent Maintenance Requests',
        Component: RecentMaintenanceRequests,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            // { name: "/Occupancy/checkin", link: "/home/checkin" },
            {
                name: '/Property/Recent-Maintenance-Requests',
                link: '/home/recent-maintenance-requests'
            }
        ]
    },

    {
        path: '/home/recent-maintenance',
        exact: true,
        name: 'Recent Maintenance',
        Component: RecentMaintenance,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            // { name: "/Occupancy/checkin", link: "/home/checkin" },
            {
                name: '/Property/Recent-Maintenance',
                link: '/home/recent-maintenance'
            }
        ]
    },

    {
        path: '/home/recent-incidents',
        exact: true,
        name: 'RecentIncident',
        Component: RecentIncident,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            // { name: "/Occupancy/checkin", link: "/home/checkin" },
            {
                name: '/Residents/checkout/recent-incidents',
                link: '/home/recent-incidents/'
            }
        ]
    },

    {
        path: '/home/list-current-residents',
        exact: true,
        name: 'currentresidents',
        Component: ListCurrentResidents,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            // { name: "/Occupancy/checkin", link: "/home/checkin" },
            {
                name: '/Residents/Current-Residents',
                link: '/home/list-current-residents'
            }
        ]
    },

    {
        path: '/home/checkout/recent-checkout',
        exact: true,
        name: 'RecentCheckouts',
        Component: RecentCheckouts,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            // { name: "/Occupancy/checkin", link: "/home/checkin" },
            {
                name: '/Occupancy/Recent Checkouts',
                link: '/home/checkouts/'
            }
        ]
    },
    {
        path: '/home/group-messages/',
        exact: true,
        name: 'GroupMessages',
        Component: GroupMessages,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            // { name: "/Occupancy/checkin", link: "/home/checkin" },
            {
                name: '/Occupancy/Group Messages',
                link: '/home/group-messages/'
            }
        ]
    },

    {
        path: '/home/checkout',
        exact: true,
        name: 'Checkout',
        Component: Checkout,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            { name: '/Occupancy/Checkout', link: '/home/checkout' }
        ]
    },
    {
        path: '/home/access-control',
        exact: true,
        name: 'Access-Control',
        Component: AccessControl,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            { name: '/Occupancy/Access-control', link: '/home/access-control' }
        ]
    },
    {
        path: '/home/incidents',
        exact: true,
        name: 'Incidents',
        Component: Incidents,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            { name: '/Residents/Incidents', link: '/home/incidents' }
        ]
    },
    {
        path: '/home/incidents/update/:id',
        exact: true,
        name: 'Incidents',
        Component: UpdateIncident,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            { name: '/Residents/Incidents', link: '/home/incidents' }
        ]
    },
    {
        path: '/home/warnings',
        exact: true,
        name: 'Warnings',
        Component: Warnings,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            { name: '/Residents/Warnings', link: '/home/warnings' }
        ]
    },
    {
        path: '/home/status',
        exact: true,
        name: 'Status',
        Component: Status,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            { name: '/Residents/Status', link: '/home/status' }
        ]
    },
    {
        path: '/home/status/register/:id',
        exact: true,
        name: 'Status',
        Component: RegisterStatus,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            { name: '/Residents/Status/Register', link: '/home/status/register' }
        ]
    },
    {
        path: '/home/property-maintenance',
        exact: true,
        name: 'Property-Maintenance',
        Component: PropertyMaintenance,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            { name: '/Property/Maintenance', link: '/home/property-maintenance' }
        ]
    },
    {
        path: '/home/edit/property-maintenance/:id',
        exact: true,
        name: 'Property-Maintenance',
        Component: EditPropertyMaintenance,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            { name: '/Property/Edit-maintenance', link: '/home/edit/property-maintenance' }
        ]
    },
    {
        path: '/home/meter-readings',
        exact: true,
        name: 'Meter-Readings',
        Component: MeterReadings,
        type: 'private',
        breadcrumb: [
            { name: 'Home', link: '/home' },
            { name: '/Property/MeterReadings', link: '/home/meter-readings' }
        ]
    },
    {
        path: '/employee/employee-details/:id',
        exact: true,
        name: 'Employee-Details',
        Component: EmployeeDetails,
        type: 'private',
        breadcrumb: [
            { name: 'Employees', link: '/reports' },
            {
                name: '/Employee-details',
                link: '/employee/employee-details/:id'
            }
        ]
    },
    {
        path: '/customer/customer-details/:id',
        exact: true,
        name: 'Customer-Details',
        Component: CustomerDetails,
        type: 'private',
        breadcrumb: [
            { name: 'Customers', link: '/customers' },
            {
                name: '/Customer-details',
                link: '/customer/customer-details/:id'
            }
        ]
    },
    {
        path: '/property/property-details/:id',
        exact: true,
        name: 'Property-Details',
        Component: PropertyDetails,
        type: 'private',
        breadcrumb: [
            { name: 'Properties', link: '/properties' },
            {
                name: '/Property-Details',
                link: '/property/property-details/:id'
            }
        ]
    },
    {
        path: '/property/property-units/:id',
        exact: true,
        name: 'Property-Unites',
        Component: PropertyUnites,
        type: 'private',
        breadcrumb: [
            { name: 'Property', link: '/properties' },
            {
                name: '/Property-Units',
                link: '/property/property-units/:id'
            }
        ]
    }
];

export default routes;
