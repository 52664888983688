import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './header.css';
import { Avatar } from 'antd';
import { useLocation } from 'react-router-dom';
// import bellIcon from '../../assets/icons/bell.png';
import avtarImage from '../../assets/icons/s-logo.png';
// import SearchBox from './Components/SearchBox';
import DropDown from './Components/DropDown';
import routes from '../../routes/private';
import logoForLargeScreen from '../../assets/images/castle-logo.png';
// import { Badge } from 'reactstrap';
import { findIndexForBreadCrumb, permissions } from '../../Helpers';
import SideBar from '../SideBar/Index';
import { MenuButton, PageAndMenu, SlideOutMenu } from 'react-responsive-ui';
import { connect } from 'react-redux';
import { CallGetMethod } from '../../API';
import { API_URL_LIST } from '../../API/API-List';
import * as types from '../../redux/actions/actionTypes';
import CrossIcon from '../../Styles/icons/Cross';
import { Badge, Col, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from 'reactstrap';
import { MenuOutlined } from '@ant-design/icons';

const Index = ({ properties, add, deleteRecord, USER }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    // const [isExpanded, setIsExpanded] = React.useState(true);
    const currentPath = location.pathname;
    const breadcrumbIndex = findIndexForBreadCrumb(routes, currentPath);
    const breadcrumb = routes[breadcrumbIndex].breadcrumb;
    // const onCollapse = () => setIsExpanded(true);
    // const onExpand = () => setIsExpanded(false);

    //
    //
    const [openSide, setOpenSide] = useState(false);
    //
    //
    //
    //
    useEffect(() => {
        const gettingProperties = async () => {
            let APIs_Array = [];
            await CallGetMethod(API_URL_LIST.API_PROPERTY_LIST_DROPDOWN, '', {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }).then(async (response) => {
                APIs_Array = response.data?.property?.data || [];

                dispatch({
                    type: types.ALL_RECORDS,
                    data: {
                        keyOfData: 'propertiesList',
                        data: APIs_Array
                    }
                });
                // dispatch({ type: types.SAVE_PROPERTY_ID, data: { id: APIs_Array[0].id, name: APIs_Array[0]?.property_name } });
                dispatch({
                    type: types.SAVING_HOUSE_RULES,
                    data: APIs_Array[0].house_rules
                });
                dispatch({
                    type: types.DOC_NO_1,
                    data: APIs_Array[0].hmo_license
                });
                dispatch({ type: types.ADD_RECORD, data: false });
                dispatch({ type: types.DELETE_RECORD, data: false });
            });
        };
        if (properties.length === 0 || add || deleteRecord) {
            gettingProperties();
        }
    }, [add, dispatch, properties.length, deleteRecord]);
    //
    //
    //
    //
    //
    //
    useEffect(() => {
        const gettingProperties = async () => {
            let APIs_Array = [];
            await CallGetMethod(API_URL_LIST.API_PROPERTY_LIST_DROPDOWN, '', {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }).then(async (response) => {
                APIs_Array = response.data?.property?.data || [];
                dispatch({ type: types.SAVE_PROPERTY_ID, data: { id: APIs_Array[0].id, name: APIs_Array[0]?.property_name } });
            });
        };
        gettingProperties();
    }, [properties.length]);
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    //
    // const USER_ROLE = localStorage.getItem('user_role');
    //
    // getting user profile
    const gettingUser = async () => {
        await CallGetMethod(API_URL_LIST?.GET_USER_PROFILE_API_URL, '', {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }).then((response) => {
            dispatch({
                type: types.ALL_RECORDS,
                data: {
                    keyOfData: 'USER',
                    data: response?.data || {}
                }
            });
            dispatch({ type: types.ADD_RECORD, data: false });
        });
    };
    useEffect(() => {
        if (Object.keys(USER).length === 0 || add) {
            gettingUser();
        }
    }, [add]);
    //
    //
    //

    //
    //
    //
    useEffect(() => {
        if (Object.keys(USER).length > 0) {
            const permission = permissions(USER);
            Object.keys(permission).forEach((key) => {
                dispatch({ type: types.SAVE_PERMISSION, data: { keyOfData: key.toString(), data: permission[key] } });
            });
        }
    }, [USER]);
    //
    //
    //
    //
    //
    //
    //
    //
    const gettingSupportVideos = async () => {
        await CallGetMethod(API_URL_LIST?.GET_ALL_TUTORIALS_API_URL, `&pagination=${Number(0)}`, {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }).then((response) => {
            dispatch({
                type: types.ALL_RECORDS,
                data: {
                    keyOfData: 'supportVideosForScreens',
                    data: response.data?.property?.data || []
                }
            });
        });
    };
    //
    //
    useEffect(() => {
        gettingSupportVideos();
    }, []);
    //

    return (
        <>
            {/* // side bar for small devices start */}
            <Row className="mt-4 d-xs-flex align-items-center medium-sidebar-icon w-100">
                <Col xs={1}>
                    <div className="">
                        <div onClick={() => setOpenSide(true)} className=" d-flex align-items-center px-2">
                            <MenuOutlined style={{ fontSize: '28px' }} />
                        </div>
                        <Offcanvas style={{ background: '#5b1a41', width: '300px' }} isOpen={openSide} toggle={function noRefCheck() {}}>
                            <div style={{ cursor: 'pointer' }} className="me-3 mt-3 d-flex align-items-center justify-content-end" onClick={() => setOpenSide(false)}>
                                <CrossIcon />
                            </div>
                            <OffcanvasBody>
                                <SideBar setOpenSide={setOpenSide} />
                            </OffcanvasBody>
                        </Offcanvas>
                    </div>
                </Col>
                <Col xs={11} className="sm-show dropdown-box">
                    <div className="w-100 ms-4">
                        <DropDown />
                    </div>
                </Col>
            </Row>
            <div className="sm-show m-2">
                <span className="sm-home-breadcrumb">{breadcrumb[0] && breadcrumb[0].name}</span>
                {breadcrumb && React.Children.toArray(breadcrumb.slice(1, breadcrumb.length).map((item) => <span className="sm-breadcrumb-item">{item.name && item.name}</span>))}
            </div>
            {/* // side bar for small devices end */}

            {/* /////////////////////////////////////// */}

            <div className="sm-hide header-div justify-content-between w-100">
                <div className="">
                    {currentPath === '/home' ?
                    <></>
                    :
                    <>
                        <span className="home-breadcrumb mx-2">{breadcrumb[0] && breadcrumb[0].name}</span>
                        {breadcrumb && React.Children.toArray(breadcrumb.slice(1, breadcrumb.length).map((item) => <span className="breadcrumb-item">{item.name && item.name}</span>))}
                    </>}
                </div>
                {/* <div className="search-box">
                    <SearchBox />
                </div> */}
                {/* ///////////////////////////////////////// */}
                <div className="for-dropdown header-three">
                    <div className="dropdown-box">
                        <DropDown />
                    </div>
                    {/* <div className="profile" style={{ cursor: 'pointer' }}>
                        <img src={bellIcon} alt={'bell'} />
                        <Badge className="castle-badge" color="danger">
                            4
                        </Badge>
                    </div> */}
                    <div className=" d-flex justify-content-end m-2 w-100" style={{ marginLeft: 'px' }}>
                        <Avatar size={{ xs: 45, sm: 45, md: 45, lg: 45, xl: 45, xxl: 45 }} src={avtarImage} />
                    </div>
                </div>
                {/* /////////////////////////////////////////////// */}
                <div className=" d-flex">
                    <div className="for-dropdown ml-3 mr-3 one">
                        <DropDown />
                        {/* <div className="profile ml-3 mr-4" style={{ cursor: 'pointer' }}>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <div>
                                <img src={bellIcon} alt={'bell'} />
                                <Badge className="castle-badge" color="danger">
                                    4
                                </Badge>
                            </div>
                        </Dropdown>
                    </div> */}
                    </div>

                    <div style={{ marginLeft: 'px', height: '70px' }} className="two mr-1">
                        <Avatar size={{ xs: 45, sm: 45, md: 45, lg: 45, xl: 45, xxl: 45 }} src={avtarImage} />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    properties: state.root.properties,
    deleteRecord: state.root.deleteRecord,
    add: state.root.add,
    USER: state.root.USER,
    supportVideosForScreens: state.root.supportVideosForScreens
});
export default connect(mapStateToProps)(Index);
