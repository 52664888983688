import React from 'react';
import './SideBar/sidebar.css';
import { Layout } from 'antd';
// import { useSelector } from "react-redux";
// import * as types from "../redux/actions/actionTypes";
import SideBar from './SideBar/Index';
import HeaderComponent from './Header/Index.jsx';
import FooterComponent from './Footer/Index';
import logoForLargeScreen from '../assets/images/castle-logo.png';
import { CloseOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
const { Header, Sider, Content } = Layout;

const Index = ({ children }) => {
    // const state = useSelector((state) => state);
    // const { root } = state;
    // const { sidebar } = root;

    /*********************************** */
    const mediaMatch = window.matchMedia('(min-width: 1200px) and (max-width: 1440px)');
    const [matches, setMatches] = React.useState(mediaMatch.matches);

    React.useEffect(() => {
        const handler = (e) => setMatches(e.matches);
        mediaMatch.addListener(handler);
        return () => mediaMatch.removeListener(handler);
    });
    /*********************************** */
    return (
        <>
            <Layout>
                <Sider width={matches ? '225px' : '320px'} className="sidebar">
                    <div
                        className="menu-close-open"
                        onClick={() => {
                            const sidebar = document.getElementsByClassName('sidebar');
                            sidebar[0].style.display = 'none';
                        }}
                    >
                        <div className="close-icon">
                            <CloseOutlined style={{ fontSize: '16px' }} />
                        </div>
                    </div>
                    <div
                        className="sidebar-logo-container"
                        style={
                            {
                                // height: "72px",
                                // borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
                            }
                        }
                    >
                        <div
                            className="d-flex h-100"
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <img
                                className="castle-sidebar-large-logo"
                                src={logoForLargeScreen}
                                style={
                                    matches
                                        ? {
                                              height: '40px',
                                              width: '200px'
                                          }
                                        : {
                                              maxHeight: '100%',
                                              maxWidth: '100%'
                                          }
                                }
                                alt="castle-logo"
                            />
                        </div>
                    </div>
                    <SideBar />
                </Sider>
                <Layout>
                    <Header
                        className="header-background"
                        style={{
                            borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
                            // boxShadow: "inset 0px -1.59468px 0px rgba(255, 255, 255, 0.1)",
                            color: 'white'
                        }}
                    >
                        <HeaderComponent />
                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '24px 16px',
                            padding: 16,
                            minHeight: 880,
                            // paddingBottom: 150,
                            background: 'linear-gradient(137.57deg,#4a0948,#000000,#1c0411,#9b0f4b)'
                        }}
                    >
                        <div className="site-layout-background mb-5">{children}</div>
                    </Content>
                </Layout>
            </Layout>
            <FooterComponent />
        </>
    );
};

export default Index;
