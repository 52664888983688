// auth api's

import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

const API = axios.create({ baseURL: API_URL });

export const userSignIn = (data) => API.post('/api/manager/login', data);
export const forgotPassword = (data) => API.post('/api/manager/forgot', data);
export const resetPasswordAPI = (data) => API.post('/api/manager/reset-password', data);
