// auth api's
import axios from 'axios';
import { openNotification } from '../Components/Notification';
import { errorStr } from '../Helpers';
const API_URL = process.env.REACT_APP_API_URL;
const CUSTOM_API_URL = process.env.REACT_CUSTOM_APP_API_URL || 'https://stage.custom-api.castleaccommodation.co.uk/';

const API = axios.create({ baseURL: API_URL });

// Add a response interceptor
API.interceptors.response.use(
    (response) => {
        // if ('Not authorized to perform this action.') {
        //     // logoutUserIfNotAuthenticated();
        // }
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        if (error?.response?.status === 401) {
            reloadFn();
            localStorage.removeItem('accessToken');
            localStorage.removeItem('user_id');
            localStorage.removeItem('user_role');
            localStorage.removeItem('user_last_name');
            localStorage.removeItem('user_first_name');
        }
        // {"email":["The email has already been taken."]}
        openNotification('top', (error?.response?.data?.message && error?.response?.data?.message) || 'Error', (error?.response?.data?.errors && errorStr(error.response.data.errors)) || '');

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return error;
    }
);

export const CallPostMethod = (url, params, data, headers) =>
    API.post(params ? url + params : url, data, {
        headers: { ...headers, accept: 'application/json', 'Access-Control-Allow-Origin': '*',  }
    });
export const CallGetMethod = (url, params, headers) =>
    API.get(params ? url + params : url, {
        headers: { ...headers, accept: 'application/json', 'Access-Control-Allow-Origin': '*', }
    });

export const CallDeleteMethod = (url, params, headers) =>
    API.delete(params ? url + params : url, {
        headers: { ...headers, accept: 'application/json', 'Access-Control-Allow-Origin': '*', }
    });

export const CallCustomPostMethod = (url, params, data, headers) =>{
    console.log("CUSTOM_API_URL",CUSTOM_API_URL)
    const APICall = axios.create({ baseURL: CUSTOM_API_URL });
    return APICall.post(params ? url + params : url, data, {
        headers: { ...headers, accept: 'application/json', 'Access-Control-Allow-Origin': '*', 'ngrok-skip-browser-warning': true }
    });
}

const reloadFn = () => {
    window.location.reload(true);
};
