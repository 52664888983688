import * as types from '../actions/actionTypes';

export const initialMMState = {
    homeModulePermission: false,
    taskModulePermission: false,
    residentsModulePermission: false,
    properiesModulePermission: false,
    employeeModulePermission: false,
    customerModulePermission: false,
    reportingModulePermission: false,
    trainingModulePermission: false,
    settigsModulePermission: false,
    supportModulePermission: false,
    //
    //
    checkinSubModulePermission: false,
    checkoutSubModulePermission: false,
    accessControlSubModulePermission: false,
    incidentSubModulePermission: false,
    warningSubModulePermission: false,
    statusSubModulePermission: false,
    maintenanceSubModulePermission: false,
    meterReadingSubModulePermission: false,
    //
    //
    taskAddPermission: false,
    taskDeletePermission: false,
    taskViewPermission: false,
    //
    //
    residntsViewPermission: false,
    //
    //
    addPropertyPermission: false,
    viewPropertyPermission: false,
    deletePropertyPermission: false,
    editPropertyPermission: false,
    //
    //

    addCustomerPermission: false,
    viewCustomerPermission: false,
    deleteCustomerPermission: false,
    editCustomerPermission: false,
    //
    //
    addEmployeePermission: false,
    viewEmployeePermission: false,
    deleteEmployeePermission: false,
    editEmployeePermission: false,
    //
    //
    occupancyReportPermission: false,
    currentresidentsReportPermission: false, // default
    maintenanceReportPermission: false, // default
    weeklyReportPermission: false, // default //
    fullResidentsReportPermission: false,
    //
    //
    viewTrainingPermission: false,
    //
    //
    viewSettingPermission: false,
    //
    //
    viewSupportPermission: false,
    addSupportPermission: false,
    deleteSupportPermission: false
};

export const permissionReducer = (state = initialMMState, action) => {
    switch (action.type) {
        case types.SAVE_PERMISSION:
            return { ...state, [action.data.keyOfData]: action.data.data };
        case types.LOGOUT1:
            return { ...initialMMState };
        default:
            return state;
    }
};
