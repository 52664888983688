import { userSignIn, forgotPassword, resetPasswordAPI } from '../../API/auth';

export const login = async (user, callback) => {
    const { username, password } = user;
    await userSignIn({ email: username, password: password })
        .then((response) => {
            localStorage.setItem('accessToken', response.data?.token);
            localStorage.setItem('user_id', response?.data?.user?.id);
            localStorage.setItem('user_role', response?.data?.user?.role);
            localStorage.setItem('user_first_name', response?.data?.user?.first_name);
            localStorage.setItem('user_last_name', response?.data?.user?.last_name);
            callback(true, response);
        })
        .catch((error) => {
            callback(false, error.response);
        });
};

export const logout = () => {
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_role');
    localStorage.removeItem('user_first_name');
    localStorage.removeItem('user_last_name');

    localStorage.removeItem('accessToken');
};

export const isLoggedIn = () => {
    if (localStorage.getItem('accessToken')) {
        return true;
    }
    return false;
};

export const forgot = async (user, callback) => {
    const { email } = user;
    console.log('email', email);
    await forgotPassword({ email: email })
        .then((response) => {
            callback(true, response);
        })
        .catch((error) => {
            callback(false, error.response);
        });
};

export const resetPassword = async (data, callback) => {
    const { email, token, password, password_confirmation } = data;
    console.log('email', email, token, password, password_confirmation);
    await resetPasswordAPI({ email, token, password, password_confirmation })
        .then((response) => {
            callback(true, response);
        })
        .catch((error) => {
            callback(false, error.response);
        });
};
