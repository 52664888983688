import moment from 'moment';

export const convertBlobToBase64 = async (blob) => {
    // blob data
    return await blobToBase64(blob);
};

const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const getMimeTypeFromBase64 = (base64String) => {
    const matches = base64String.match(/^data:([a-zA-Z]+\/[a-zA-Z]+);base64,/);
    if (matches && matches.length > 1) {
        return matches[1]; // Return the file extension
    }
    return null; // Return null if file extension is not found or base64 string is invalid
};

export const getFileExtFromMimeType = (mimeType) => {
    const mimeTypes = {
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'image/gif': 'gif',
      // Add more MIME types as needed
    };
  
    // Check if the provided MIME type exists in the mapping
    if (mimeTypes[mimeType]) {
      return mimeTypes[mimeType];
    } else {
      // If the MIME type is not found, return 'unknown' or handle it as needed
      return false;
    }
}

export const convertSvgToImageFile = (url, fileName, fileType) => {
    return new Promise((resolve, reject) => {
        const imgElement = document.createElement('img');
        imgElement.src = url;

        imgElement.onload = async () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = imgElement.width;
            canvas.height = imgElement.height;
            ctx.drawImage(imgElement, 0, 0);
        
            canvas.toBlob((blob) => {
                if (!blob) {
                reject(new Error('Failed to convert image to blob'));
                return;
                }
        
                const file = new File([blob], fileName, { type: fileType });
                resolve(file);
            }, fileType);
        }
    });
};

export const findIndexForBreadCrumb = (routes, pathname) => {
    let index = 0;
    for (let i = 0; i < routes.length; i++) {
        let path1 = routes[i].path;
        let path2 = pathname;
        if (routes[i].path.includes(':id')) {
            const replaceStr = routes[i].path.split('/').pop();
            const replaceStr2 = pathname.split('/').pop();
            path1 = routes[i].path.replace(replaceStr, '');
            path2 = pathname.replace(replaceStr2, '');
        }
        if (path1 === path2) {
            index = i;
        }
    }
    return index;
};

export const formatTime = (str) => {
    const d = new Date(str);

    return moment(d).format('YYYY-MM-DD');
};
export const formatTimeAndData = (str) => {
    const d = new Date(str);

    return moment(d).format('YYYY-MM-DD HH:mm:ss');
};
export const formatTime1 = (str) => {
    const d = new Date(str);

    return moment(d).format('HH:mm:ss');
};

export const getYearsList = () => {
    const years = [];
    const startYear = 2022;
    const endYear = 2100;
    // for (let i = startYear; i <= endYear; i++) {
    const yearysObj = {
        year: new Date().getFullYear(),
        id: new Date().getFullYear()
    };
    years.push(yearysObj);
    // }
    return years;
};

export const getYearsList1 = () => {
    const years = [];
    const startYear = 2022;
    const endYear = 2100;
    for (let i = startYear; i <= endYear; i++) {
        const yearysObj = {
            year: i,
            id: i
        };
        years.push(yearysObj);
    }
    return years;
};

export const generateUniqueId = (str) => {
    const date = new Date();
    const components = [date.getYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()];

    return str ? str + components.join('') : components.join('');
};
//
//
//
//
//
export const getDaysArray = (year, month) => {
    const monthIndex = month - 1; // 0..11 instead of 1..12
    const names = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const date = new Date(year, monthIndex, 1);
    const result = [];
    while (date.getMonth() === monthIndex) {
        result.push(date.getDate() + '-' + names[date.getDay()]);
        date.setDate(date.getDate() + 1);
    }
    return result || [];
};

//
//
//
//
//
export function validateYouTubeUrl(url) {
    var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
        return url.match(p)[1];
    }
    return false;
}
//
//
//
//
// error helper
// {"email":["The email has already been taken."]}
export const errorStr = (obj) => {
    let str;
    if (typeof obj === 'string') {
        return obj;
    }
    Object.keys(obj).forEach((key) => {
        str = (
            <>
                {str} {obj[key][0]} <br />
            </>
        );
    });
    return str;
};
//
//
//
//
//
//
//
export const excelDaysColumns = () => {
    return ['F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ'];
};
//
//
//
//
//
//
export const isGoogleDriveLink = (str) => {
    const googleDriveLinkRegex = /^https?:\/\/drive\.google\.com\/(?:open\?id=|file\/d\/)([a-zA-Z0-9_-]{10,})(?:\/|$)/;
    return googleDriveLinkRegex.test(str);
};
//
export const getGoogleDriveId = (link) => {
    const match = link.match(/([a-zA-Z0-9_-]{10,})(?:\/|$)/);
    if (match) {
        return match[1];
    }
    return null;
};
//
//
//
//
//
//
//
export const permissions = (user) => {
    //
    //
    let admin = false;
    // parenst permissions
    let homeModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Home') || false;
    let taskModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Task') || false;
    let residentsModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Residents') || false;
    let properiesModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Properties') || false;
    let employeeModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Employees') || false;
    let customerModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Customers') || false;
    let reportingModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Reporting') || false;
    let trainingModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Training') || false;
    let settigsModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Settings') || false;
    let supportModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Support') || false;
    //
    //
    //
    //
    // home child permissions
    let checkinSubModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Home' && item?.name === 'Check In') || false;
    let checkoutSubModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Home' && item?.name === 'Check Out') || false;
    let accessControlSubModulePermission = false;
    let incidentSubModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Home' && item?.name === 'Incidents') || false;
    let warningSubModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Home' && item?.name === 'Warnings') || false;
    let statusSubModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Home' && item?.name === 'Status') || false;
    let maintenanceSubModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Home' && item?.name === 'Maintenance') || false;
    let meterReadingSubModulePermission = user?.role_permissions?.some((item) => item.module_name === 'Home' && item?.name === 'Meter Readings') || false;
    //
    //
    //
    //
    //
    //
    //
    // task child permissions
    let taskAddPermission = user?.role_permissions?.some((item) => item.module_name === 'Task' && item?.name === 'Add') || false;
    let taskDeletePermission = user?.role_permissions?.some((item) => item.module_name === 'Task' && item?.name === 'Delete') || false;
    let taskViewPermission = user?.role_permissions?.some((item) => item.module_name === 'Task' && item?.name === 'View') || false;
    //
    //
    //
    // residents child permissions
    let residntsViewPermission = user?.role_permissions?.some((item) => item.module_name === 'Residents' && item?.name === 'View') || false;
    //
    //
    //
    // properties child permissions
    let addPropertyPermission = user?.role_permissions?.some((item) => item.module_name === 'Properties' && item?.name === 'Add') || false;
    let viewPropertyPermission = user?.role_permissions?.some((item) => item.module_name === 'Properties' && item?.name === 'View') || false;
    let deletePropertyPermission = user?.role_permissions?.some((item) => item.module_name === 'Properties' && item?.name === 'Delete') || false;
    let editPropertyPermission = user?.role_permissions?.some((item) => item.module_name === 'Properties' && item?.name === 'Edit') || false;
    //
    //
    //
    //
    // customer child permissions
    let addCustomerPermission = user?.role_permissions?.some((item) => item.module_name === 'Customers' && item.name === 'Add') || false;
    let viewCustomerPermission = user?.role_permissions?.some((item) => item.module_name === 'Customers' && item.name === 'View') || false;
    let deleteCustomerPermission = user?.role_permissions?.some((item) => item.module_name === 'Customers' && item.name === 'Delete') || false;
    let editCustomerPermission = user?.role_permissions?.some((item) => item.module_name === 'Customers' && item.name === 'Edit') || false;
    //
    //
    //
    //
    // employee child permissions
    let addEmployeePermission = user?.role_permissions?.some((item) => item.module_name === 'Employees' && item.name === 'Add') || false;
    let viewEmployeePermission = user?.role_permissions?.some((item) => item.module_name === 'Employees' && item.name === 'View') || false;
    let deleteEmployeePermission = user?.role_permissions?.some((item) => item.module_name === 'Employees' && item.name === 'Delete') || false;
    let editEmployeePermission = user?.role_permissions?.some((item) => item.module_name === 'Employees' && item.name === 'Edit') || false;
    //
    //
    //
    //
    // reporting child permissions
    let occupancyReportPermission = user?.role_permissions?.some((item) => item.module_name === 'Reporting' && item?.name === 'Occupancy Reports') || false;
    let currentresidentsReportPermission = user?.role_permissions?.some((item) => item.module_name === 'Reporting' && item?.name === 'List Of Current Residents') || false; // default
    let maintenanceReportPermission = user?.role_permissions?.some((item) => item.module_name === 'Reporting' && item?.name === 'Maintenance') || false; // default
    let weeklyReportPermission = user?.role_permissions?.some((item) => item.module_name === 'Reporting' && item?.name === 'Weekly Reports') || false; // default //
    let fullResidentsReportPermission = user?.role_permissions?.some((item) => item.module_name === 'Reporting' && item?.name === 'Full Resident Report') || false; // default
    //
    //
    //
    //
    // training child permissions
    let viewTrainingPermission = user?.role_permissions?.some((item) => item.module_name === 'Training' && item?.name === 'View') || false;
    //
    //
    //
    //
    // settings child permissions
    let viewSettingPermission = user?.role_permissions?.some((item) => item.module_name === 'Settings' && item?.name === 'View') || false;
    //
    //
    //
    //
    // support child permissions
    let viewSupportPermission = user?.role_permissions?.some((item) => item.module_name === 'Support' && item?.name === 'View') || false;
    let addSupportPermission = user?.role_permissions?.some((item) => item.module_name === 'Support' && item?.name === 'Add') || false;
    let deleteSupportPermission = user?.role_permissions?.some((item) => item.module_name === 'Support' && item?.name === 'Delete') || false;
    let editSupportPermission = user?.role_permissions?.some((item) => item.module_name === 'Support' && item?.name === 'Edit') || false;
    //
    //
    //
    //
    //
    if (user?.role_id === 1) {
        homeModulePermission = true;
        taskModulePermission = true;
        residentsModulePermission = true;
        properiesModulePermission = true;
        employeeModulePermission = true;
        customerModulePermission = true;
        reportingModulePermission = true;
        trainingModulePermission = true;
        settigsModulePermission = true;
        supportModulePermission = true;
        //
        //
        checkinSubModulePermission = true;
        checkoutSubModulePermission = true;
        accessControlSubModulePermission = true;
        incidentSubModulePermission = true;
        warningSubModulePermission = true;
        statusSubModulePermission = true;
        maintenanceSubModulePermission = true;
        meterReadingSubModulePermission = true;
        //
        //
        taskAddPermission = true;
        taskDeletePermission = true;
        taskViewPermission = true;
        //
        //
        residntsViewPermission = true;
        //
        //
        addPropertyPermission = true;
        viewPropertyPermission = true;
        deletePropertyPermission = true;
        editPropertyPermission = true;
        //
        //

        addCustomerPermission = true;
        viewCustomerPermission = true;
        deleteCustomerPermission = true;
        editCustomerPermission = true;
        //
        //
        addEmployeePermission = true;
        viewEmployeePermission = true;
        deleteEmployeePermission = true;
        editEmployeePermission = true;
        //
        //
        occupancyReportPermission = true;
        currentresidentsReportPermission = true; // default
        maintenanceReportPermission = true; // default
        weeklyReportPermission = true; // default //
        fullResidentsReportPermission = true;
        //
        //
        viewTrainingPermission = true;
        //
        //
        viewSettingPermission = true;
        //
        //
        viewSupportPermission = true;
        addSupportPermission = true;
        deleteSupportPermission = true;
        editSupportPermission = true;
        admin = true;
    }
    //
    //
    //
    //
    //
    //
    return {
        homeModulePermission,
        taskModulePermission,
        residentsModulePermission,
        properiesModulePermission,
        employeeModulePermission,
        customerModulePermission,
        reportingModulePermission,
        trainingModulePermission,
        settigsModulePermission,
        supportModulePermission,
        //
        //
        checkinSubModulePermission,
        checkoutSubModulePermission,
        accessControlSubModulePermission,
        incidentSubModulePermission,
        warningSubModulePermission,
        statusSubModulePermission,
        maintenanceSubModulePermission,
        meterReadingSubModulePermission,
        //
        //
        taskAddPermission,
        taskDeletePermission,
        taskViewPermission,
        //
        //
        residntsViewPermission,
        //
        //
        addPropertyPermission,
        viewPropertyPermission,
        deletePropertyPermission,
        editPropertyPermission,
        //
        //

        addCustomerPermission,
        viewCustomerPermission,
        deleteCustomerPermission,
        editCustomerPermission,
        //
        //
        addEmployeePermission,
        viewEmployeePermission,
        deleteEmployeePermission,
        editEmployeePermission,
        //
        //
        occupancyReportPermission,
        currentresidentsReportPermission, // default
        maintenanceReportPermission, // default
        weeklyReportPermission, // default //
        fullResidentsReportPermission,
        //
        //
        viewTrainingPermission,
        //
        //
        viewSettingPermission,
        //
        //
        viewSupportPermission,
        addSupportPermission,
        deleteSupportPermission,
        editSupportPermission,
        admin
    };
};

export const isBase64 = (str) => {
    return !/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/.test(str);
};

//
//
//
export const strValidate = (inputtxt) => {
    const letters = /^[A-Za-z]+$/;
    if (inputtxt.match(letters)) {
        return true;
    } else {
        return false;
    }
};
//
