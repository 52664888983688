import { Col, Image, List, Row } from 'antd';
import './sidebar.css';
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import home from '../../assets/icons/Vector.svg';
import task from '../../assets/icons/task.svg';
import employee from '../../assets/icons/employee.svg';
import customer from '../../assets/icons/customer.svg';
import profile from '../../assets/icons/profile.svg';
import setting from '../../assets/icons/setting.svg';
import property from '../../assets/icons/property.svg';
import residentsIcon from '../../assets/icons/Residents.svg';
import supportIcon from '../../assets/icons/Support.svg';
import TrainingIcon from '../../assets/icons/Training-1.svg';
import Reporting from '../../assets/icons/Reporting.svg';
import logout from '../../assets/icons/logout.svg';
import '../../assets/styles/Sidebar/Index.css';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import * as types from '../../redux/actions/actionTypes';
// sidebar-list-active
const Index = ({ permissions, setOpenSide }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const currentPath = location.pathname;
    const { root } = useSelector((state) => state);
    const { sidebar } = root;
    const [sideBarList, setSideBarList] = useState([
        // {
        //     img: home,
        //     title: 'Home',
        //     link: '/home',
        //     active: true
        // },
        // {
        //     img: task,
        //     title: 'Task',
        //     link: '/task',
        //     active: false
        // },
        // {
        //     img: residentsIcon,
        //     title: 'Residents',
        //     link: '/residents',
        //     active: false
        // },
        // {
        //     img: property,
        //     title: 'Properties',
        //     link: '/properties',
        //     active: false
        // },
        // {
        //     img: employee,
        //     title: 'Employees',
        //     link: '/employees',
        //     active: false
        // },
        // {
        //     img: customer,
        //     title: 'Customers',
        //     link: '/customers',
        //     active: false
        // },
        // {
        //     img: Reporting,
        //     title: 'Reporting',
        //     link: '/reporting',
        //     active: false
        // },
        // {
        //     img: TrainingIcon,
        //     title: 'Training',
        //     link: '/training',
        //     active: false
        // },
        // {
        //     img: profile,
        //     title: 'My Profile',
        //     link: '/my-profile',
        //     active: false
        // },
        // {
        //     img: setting,
        //     title: 'Settings',
        //     link: '/settings',
        //     active: false
        // },
        // {
        //     img: supportIcon,
        //     title: 'Support',
        //     link: '/support',
        //     active: false
        // }
    ]);
    useEffect(() => {
        if (Object.keys(permissions).length > 0) {
            const array = [];
            if (permissions?.homeModulePermission) {
                array.push({
                    img: home,
                    title: 'Home',
                    link: '/home',
                    active: true,
                    marginLeft: '17px',
                    imageMargin: ''
                });
            }
            if (permissions?.taskModulePermission) {
                array.push({
                    img: task,
                    title: 'Tasks',
                    link: '/task',
                    active: false,
                    marginLeft: '17px',
                    imageMargin: ''
                });
            }
            if (permissions?.residentsModulePermission) {
                array.push({
                    img: residentsIcon,
                    title: 'Residents',
                    link: '/residents',
                    active: false,
                    marginLeft: '6px',
                    imageMargin: '11px'
                });
            }
            if (permissions?.properiesModulePermission) {
                array.push({
                    img: property,
                    title: 'Properties',
                    link: '/properties',
                    active: false,
                    marginLeft: '8px',
                    imageMargin: '9px'
                });
            }
            if (permissions?.employeeModulePermission) {
                array.push({
                    img: employee,
                    title: 'Employees',
                    link: '/employees',
                    active: false,
                    marginLeft: '9px',
                    imageMargin: '9px'
                });
            }
            if (permissions?.customerModulePermission) {
                array.push({
                    img: customer,
                    title: 'Customers',
                    link: '/customers',
                    active: false,
                    marginLeft: '9px',
                    imageMargin: '8px'
                });
            }
            if (permissions?.reportingModulePermission) {
                array.push({
                    img: Reporting,
                    title: 'Reporting',
                    link: '/reporting',
                    active: false,
                    marginLeft: '5px',
                    imageMargin: '12px'
                });
            }
            if (permissions?.trainingModulePermission) {
                array.push({
                    img: TrainingIcon,
                    title: 'Training',
                    link: '/training',
                    active: false,
                    marginLeft: '11px',
                    imageMargin: '6px'
                });
            }
            array.push({
                img: profile,
                title: 'My Profile',
                link: '/my-profile',
                active: false,
                marginLeft: '17px',
                imageMargin: ''
            });
            if (permissions?.settigsModulePermission) {
                array.push({
                    img: setting,
                    title: 'Settings',
                    link: '/settings',
                    active: false,
                    marginLeft: '18px',
                    imageMargin: ''
                });
            }
            if (permissions?.supportModulePermission) {
                array.push({
                    img: supportIcon,
                    title: 'Support',
                    link: '/support',
                    active: false,
                    marginLeft: '16px',
                    imageMargin: ''
                });
            }
            setSideBarList(array);
        }
    }, [permissions]);

    return (
        <>
            {/* <div
        className="menu-close-open"
        onClick={() => {
          const sidebar = document.getElementsByClassName("sidebar");
          sidebar[0].style.display = "none";
        }}
      >
        <div className="close-icon">
          <CloseOutlined style={{ fontSize: "16px" }} />
        </div>
      </div> */}
            <Row className="sidebar side-bar-scroll mb-3" justify="center">
                <Col span={20}>
                    <List
                        className="mt-4"
                        style={{ marginTop: '50px' }}
                        itemLayout="horizontal"
                        bordered={0}
                        dataSource={sideBarList}
                        renderItem={(item) => (
                            <Link style={{ textDecoration: 'none' }}>
                                <List.Item
                                    to={item.link}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (setOpenSide) {
                                            setOpenSide(false);
                                        }

                                        navigate(item.link);
                                    }}
                                    className={currentPath === item.link ? 'sidebar-list-active mt-2' : 'sidebar-list mt-2'}
                                >
                                    <List.Item.Meta
                                        style={{
                                            marginLeft: item.marginLeft,
                                            width: '266px'
                                        }}
                                        avatar={<Image style={{ marginLeft: item.imageMargin, height: '24px', width: '24px' }} src={item.img} preview={false} />}
                                        title={
                                            !sidebar.collapsed ? (
                                                <span className={currentPath === item.link ? 'sidebar-list-text-active font-family-class' : 'sidebar-list-text font-family-class'}>{item.title}</span>
                                            ) : (
                                                ''
                                            )
                                        }
                                    />
                                </List.Item>
                            </Link>
                        )}
                    />
                    <div className="d-flex logout-container mb-5">
                        <div className="mt-auto">
                            <hr className="logout-hr" />
                            <div
                                className="d-flex sidebar-list"
                                onClick={() => {
                                    dispatch({ type: types.LOGOUT });
                                    dispatch({ type: types.LOGOUT1 });
                                    navigate('/');
                                    localStorage.removeItem('accessToken');
                                    localStorage.removeItem('user_id');
                                    localStorage.removeItem('user_role');
                                    localStorage.removeItem('user_last_name');
                                    localStorage.removeItem('user_first_name');
                                }}
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '65px',
                                    width: '140px'
                                }}
                            >
                                <div className="d-flex w-100" style={{ justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="mr-2">
                                        <img src={logout} alt="logout" />
                                    </div>
                                    <div className="mr-2">
                                        <span className="sidebar-list-text">Logout</span>
                                    </div>
                                </div>
                            </div>
                            {/* <List.Item
                className="sidebar-list"
                onClick={() => {
                  navigate("/");
                  localStorage.removeItem("accessToken");
                }}
              >
                <List.Item.Meta
                  style={{
                    marginLeft: "20px",
                    width: "200px",
                  }}
                  avatar={<Image src={logout} />}
                  title={
                    !sidebar.collapsed ? (
                      <span className="sidebar-list-text">logout</span>
                    ) : (
                      ""
                    )
                  }
                />
              </List.Item> */}
                        </div>
                    </div>

                    {/* </div> */}
                </Col>
            </Row>
        </>
    );
};
const mapStateToProps = (state) => ({
    permissions: state.permissions
});
export default connect(mapStateToProps)(Index);
