import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import allRoutes from './routes';

function App() {
    const { PublicRoutes, PrivateRoutes } = allRoutes;
    const routes = [...PublicRoutes, ...PrivateRoutes];
    return (
        <BrowserRouter>
            <Routes>
                {React.Children.toArray(
                    routes.map(({ path, exact, Component, type }) => (
                        <Route
                            key={path}
                            path={path}
                            exact={exact}
                            element={
                                type === 'public' ? (
                                    <Component />
                                ) : (
                                    <PrivateRoute>
                                        <Component />
                                    </PrivateRoute>
                                )
                            }
                        />
                    ))
                )}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
