import * as types from '../actions/actionTypes';

export const initialMMState = {
    USER: {},
    sidebar: {
        collapsed: false
    },
    add: false,
    deleteRecord: false,
    // all data of applcation
    employees: [],
    employeesPaginationLinks: {},
    customers: [],
    customersPaginationLinks: {},
    properties: [],
    propertiesPaginationLinks: {},
    employeeGroupsList: [],
    //
    regionsList: [],
    //
    recentcheckIns: [
        // {
        //   customerName: "Customer Name",
        //   contact: "111111111",
        //   comments: "comments Section",
        //   checkedIn: "10:10",
        //   checkedOut: "10:15",
        // },
    ],
    //
    //
    recentcheckOuts: [],
    //
    //
    //
    recentIncidents: [],

    propertiesList: [],
    customersListing: [],
    propertyUnits: [],
    occupancyReport: [],
    // active property id
    activePropertyId: '',
    activePropertyName: '',
    // property units
    propertyUnitsByID: [],
    // get all occupancy reports
    occupancyReports: [],
    //
    houseRules: '',
    doc_1: '',
    //
    //
    // checkout room
    checkoutRoomNo: '',

    //
    checkinRecord: {},
    //
    //
    //
    submittedAllStatuses: [],
    //
    //
    //
    //
    //
    //
    //
    recentMaintanceRequests: [],
    //
    //
    //
    //
    //
    supportVideos: [],
    supportVideosForScreens: [],
    //
    //
    //
    //
    //
    allTrainings: [],
    //
    //
    //
    //
    //
    //
    //
    propertyTasks: [],
    userTasks: [],
    //
    //
    warningTemplates: [],
    archieveTasks: []
};

export const rootReducer = (state = initialMMState, action) => {
    switch (action.type) {
        case types.SIDEBAR_COLLAPSED:
            return { ...state, sidebar: { collapsed: action.data.collapsed } };
        case types.SET_CHECKIN_DATA:
            return { ...state, checkinData: action.data };
        case types.ALL_RECORDS:
            return { ...state, [action.data.keyOfData]: action.data.data };
        case types.ADD_RECORD:
            return { ...state, add: action.data };
        case types.DELETE_RECORD:
            return { ...state, deleteRecord: action.data };
        case types.SAVE_PROPERTY_ID:
            return { ...state, activePropertyId: action?.data?.id, activePropertyName: action?.data?.name };
        case types.SAVING_HOUSE_RULES:
            return { ...state, houseRules: action.data };
        case types.DOC_NO_1:
            return { ...state, doc_1: action.data };
        case types.SAVING_CHECKOUT_ROOM_NO:
            return { ...state, checkoutRoomNo: action.data };
        case types.LOGOUT:
            return { ...initialMMState };

        default:
            return state;
    }
};
