import React from 'react';
const Signin = React.lazy(() => import('../../Pages/Signin/Index'));
const Forgot = React.lazy(() => import('../../Pages/Forgot/Index'));
const ResetPassword = React.lazy(() => import('../../Pages/ResetPassword/Index'));

const routes = [
    {
        path: '/',
        exact: true,
        name: 'Signin',
        Component: Signin,
        type: 'public'
    },
    {
        path: '/forgot-password',
        exact: true,
        name: 'Forgot',
        Component: Forgot,
        type: 'public'
    },
    {
        path: '/reset-password',
        exact: true,
        name: 'Reset Password',
        Component: ResetPassword,
        type: 'public'
    }
];

export default routes;
