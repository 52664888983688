import React from 'react';

const Cross = () => {
    return (
        <>
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23 2L2 23M2 2L23 23" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    );
};

export default Cross;
