import React, { useState } from 'react';
import './footer.css';
import { useEffect } from 'react';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import { isLoggedIn } from '../../lib/auth';

const Index = () => {
    const user = localStorage.getItem('user_id');
    const first_name = localStorage.getItem('user_first_name');
    const last_name = localStorage.getItem('user_last_name');
    const [afterUpdateUser, setAfterUpdate] = useState(null);
    useEffect(() => {
        setAfterUpdate(user);
    }, [user]);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const date = new Date();
    const dayName = days[date.getDay()];
    const monthName = months[date.getMonth()];
    //
    const auth = isLoggedIn();
    //
    if (auth) {
        return (
            <>
                <div className="fixed-bottom d-none d-md-flex d-sm-flex d-lg-flex footer-component flex-wrap justify-content-lg-between justify-content-xl-between justify-content-sm-center justify-content-md-center align-items-center pt-3">
                    <div className="pt-0 ms-5 me-5">
                        <p className="footer-text font-family-class">
                            {afterUpdateUser && afterUpdateUser ? (
                                <span className="footer-text font-family-class">
                                    You are logged in as{' '}
                                    <strong className="font-family-class">
                                        {first_name && first_name !== 'null' ? first_name : ''} {last_name && last_name !== 'null' ? last_name : ''}
                                    </strong>
                                </span>
                            ) : (
                                ''
                            )}
                        </p>
                    </div>
                    <div className="pt-0 m-2">
                        <p className="footer-text font-family-class">
                            © Castle Accommodation <strong> {date.getFullYear()}</strong>
                        </p>
                    </div>
                    <div className="pt-0 m-2 mx-lg-5">
                        <p className="footer-text font-family-class">
                            {dayName},
                            <strong>
                                <span className="font-family-class">
                                    {date.getDate()} {monthName} {date.getFullYear()}
                                </span>
                            </strong>
                            <strong className="font-family-class">, week {moment(moment().toDate(), 'MM-DD-YYYY').isoWeek()} </strong>
                        </p>
                    </div>
                </div>
                <div className="small-devices-footer footer-component p-3 fixed-bottom">
                    <Row className=" sm-hide d-flex justify-content-between">
                        <Col>
                            <div className="small-footer-text font-family-class">
                                {afterUpdateUser && afterUpdateUser ? (
                                    <span className="small-footer-text font-family-class">
                                        You are logged in as
                                        <strong className="font-family-class">
                                            {first_name && first_name !== 'null' ? first_name : ''} {last_name && last_name !== 'null' ? last_name : ''}
                                        </strong>
                                    </span>
                                ) : (
                                    ''
                                )}
                            </div>
                        </Col>
                        <Col className="text-center w-100">
                            <div className=" w-100 small-footer-text text-center font-family-class">
                                {dayName},
                                <strong>
                                    <span className="font-family-class">
                                        {date.getDate()} {monthName} {date.getFullYear()}
                                    </span>
                                </strong>
                                <strong className="font-family-class">, week {moment(moment().toDate(), 'MM-DD-YYYY').isoWeek()} </strong>
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <div className=" text-center w-100">
                        <div className="small-footer-text font-family-class">
                            © Castle Accommodation <strong>{date.getFullYear()}</strong>
                        </div>
                    </div>
                </div>
                <div className="footer-component p-3 sm-show fixed-bottom">
                    <div className="small-footer-text text-center w-100">
                        {dayName},
                        <strong>
                            <span className="font-family-class">
                                {date.getDate()} {monthName} {date.getFullYear()}
                            </span>
                        </strong>
                        <strong className="font-family-class">, week {moment(moment().toDate(), 'MM-DD-YYYY').isoWeek()} </strong>
                    </div>
                </div>
            </>
        );
    }
    if (!auth) {
        return (
            <>
                <div className="px-4 p-3 fixed-bottom footer-component d-flex flex-wrap justify-content-between align-items-center pt-3">
                    <div className="pt-0 m-2 sm-footer-center-text">
                        <div className="footer-text font-family-class">
                            © Castle Accommodation <strong> {date.getFullYear()}</strong>
                        </div>
                    </div>
                    <div className="sm-footer-center-text footer-text">
                        {dayName},
                        <strong>
                            <span className="font-family-class">
                                {date.getDate()} {monthName} {date.getFullYear()}
                            </span>
                        </strong>
                        <strong className="font-family-class">, week {moment(moment().toDate(), 'MM-DD-YYYY').isoWeek()} </strong>
                    </div>
                </div>
            </>
        );
    }
};

export default Index;
