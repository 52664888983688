import React from 'react';
import { ActivityIndicator } from 'react-responsive-ui';
import { Navigate } from 'react-router-dom';
import Layout from './Layout/Index';
import { isLoggedIn } from './lib/auth';

const PrivateRoute = ({ children }) => {
    const auth = isLoggedIn();
    if (!auth) {
        return <Navigate to="/" replace />;
    }
    return (
        <Layout>
            <React.Suspense
                fallback={
                    <div className=" d-flex justify-content-center w-100">
                        <ActivityIndicator className="rrui__activity-indicator" />
                    </div>
                }
            >
                {children}
            </React.Suspense>
        </Layout>
    );
};

export default PrivateRoute;
