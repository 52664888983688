import React from 'react';
import '../header.css';
import { Input } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import * as types from '../../../redux/actions/actionTypes';

const DropDown = ({ propertiesList }) => {
    const dispatch = useDispatch();

    return (
        <>
            <Input
                onChange={(e) => {
                    const obj = propertiesList.find((item) => item.id == e.target.value);
                    dispatch({ type: types.SAVE_PROPERTY_ID, data: { id: e.target.value, name: obj?.property_name } });

                    dispatch({
                        type: types.SAVING_HOUSE_RULES,
                        data: obj.house_rules
                    });
                    dispatch({
                        type: types.SAVING_CHECKOUT_ROOM_NO,
                        data: ''
                    });

                    dispatch({
                        type: types.DOC_NO_1,
                        data: obj.hmo_license
                    });
                }}
                name="selectedProperty"
                // value={
                //   checkinData.selectedCustomer &&
                //   checkinData.selectedCustomer
                // }
                className="dropdown-btn"
                type="select"
            >
                {React.Children.toArray(
                    propertiesList &&
                        propertiesList.map((item) => (
                            <option className="select-option" value={item.id}>
                                {(item.property_name && item.property_name) || ''}
                            </option>
                        ))
                )}
            </Input>
        </>
    );
};
const mapStateToProps = (state) => ({
    propertiesList: state.root.propertiesList,
    add: state.root.add
});
export default connect(mapStateToProps)(DropDown);
